import * as React from "react";
import CommonApi from "../api/CommonApi";
import UrlaubsanfrageApi from "../api/UrlaubsanfrageApi";
import { useQuery } from "react-query";
import { Button, Dropdown, DropdownItemProps, Input, Modal, Segment, Table } from "semantic-ui-react";
import { UrlaubsanfrageModel, EUrlaubsantragStatus, SetUrlaubsanfragePOSTRequest, EAZTyp, ELookup , AbwesenheitsAnfrageModel } from "../generated";
import useAsyncEdit from "../hooks/useAsyncEdit";
import { InfoBox } from "./InfoBoxen/InfoBox";
import SimpleForm, { SimpleFormDatePickerUrlaub } from "./SimpleForm";
import { dateToShortDateString } from "./Helper";
import * as Yup from "yup";
import useMessages from "../hooks/useMessages";
import Popup from "../../../node_modules/semantic-ui-react/dist/commonjs/modules/Popup/Popup";
import Icon from "../../../node_modules/semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import useDocumentTitle, { DOCUMENT_TITLES } from "../hooks/useDocumentTitle";

interface IAbwesenheitProps { }


export const Abwesenheit: React.FC<IAbwesenheitProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.abwesenheitsantrag)
  const abwesenheitsOptionen: {
    key: string;
    text: string;
    value: EAZTyp;
  }[] = [];

  const abwesenheitsTypen = useQuery(["getAbwesenheitsTypenGET"], () =>
    UrlaubsanfrageApi.getAbwesenheitsTypenGET()
  );
  const [warningOpen, setWarningOpen] = React.useState<boolean>(false);
  const msg = useMessages();
  if (abwesenheitsTypen.data) {
    abwesenheitsTypen.data.forEach((Typ) => {
      abwesenheitsOptionen.push({
        key:  Typ.key!,
        text: Typ.key!,
        value: Typ.value!
      });
    });
  }
  const [notiz, setNotiz] = React.useState<string>(' ');
  const [option, setOption] = React.useState<string>('Urlaub');
  const [tagesStempel, setTagesStempel] = React.useState<ELookup>(ELookup.None);
  let abwesenheitsArten: DropdownItemProps[] = [];
  if (abwesenheitsTypen.data) {
    abwesenheitsTypen.data.forEach((Typ) => {
      abwesenheitsArten.push({
        key:  Typ.key!,
        text: Typ.key!,
        value: ((Typ.tagesstempelID !== undefined) && (Typ.tagesstempelID !== null)) ? Typ.tagesstempelID : Typ.value!
      });
    });
  }

  let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  const minDatum = new Date(tomorrow);

  let urlaubsAnfrage: UrlaubsanfrageModel = {
    bis: tomorrow,
    von: tomorrow,
    id: undefined,
    mitarbeiterID: undefined,
    notizen: " ",
    status: EUrlaubsantragStatus.Offen,
    typ: EAZTyp.Urlaub,
    tagesstempelName: " ",
    tagesstempelAnfrage: ELookup.None
  };

  /** Fetch von Daten die Gebraucht werden */
  const commonVersions = useQuery(["getVersionGET"], () =>
    CommonApi.getVersionGET({clearCache: false})
  );
  const [wrongDate, setWrongDate] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>((option === EAZTyp.Sonderurlaub && notiz.length < 2));
  const [bisDate, setBisDate] = React.useState<Date | undefined>(undefined);
  const [vonDate, setVonDate] = React.useState<Date | undefined>(tomorrow);
  const abwesenheitsData = useAsyncEdit<AbwesenheitsAnfrageModel, SetUrlaubsanfragePOSTRequest>
    ({
      queryKey: "getUrlaubsanfragenGET",
      default: {},
      id: undefined,
      load: (id) => UrlaubsanfrageApi.getUrlaubsanfragenGET(),
      save: async (v, prev) => {
        try {
          const result = await UrlaubsanfrageApi.setUrlaubsanfragePOST({
            del: v.del,
            urlaubsanfrageModel: {
              id: v.urlaubsanfrageModel?.id,
              mitarbeiterID: undefined,
              typ: v.urlaubsanfrageModel?.typ,
              von: v.urlaubsanfrageModel?.von,
              bis: v.urlaubsanfrageModel?.bis,
              notizen: v.urlaubsanfrageModel?.notizen,
              status: v.urlaubsanfrageModel?.status,
              tagesstempelAnfrage: v.del ? v.urlaubsanfrageModel?.tagesstempelAnfrage : tagesStempel,
              tagesstempelName: v.urlaubsanfrageModel?.tagesstempelName
            }
          });
          setInitModel(urlaubsAnfrage);
          msg.addMessage({ header: v.del ? "Antrag erfolgreich gelöscht!" : "Antrag erfolgreich gespeichert!"  }, "success", true, true);
          return result;
        } catch (e) {
          setInitModel(v.urlaubsanfrageModel ? v.urlaubsanfrageModel : urlaubsAnfrage);
          return prev;
        }
      }
    });
  const [initModel, setInitModel] = React.useState<UrlaubsanfrageModel>(urlaubsAnfrage);
  let note = ' ';
  if (bisDate !== undefined && !warningOpen && (initModel.bis !== bisDate || initModel.von !== vonDate)) {
   initModel.bis = bisDate;
   initModel.von = vonDate;
  }
  const fields = [
    {
      component: "div",
      props: {
        className: "m-bottom-15"
      },
      colSize: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12
      },
      fields: [
        {
          validation: Yup.date().required("Von-Datum ist erforderlich."),
          component: SimpleFormDatePickerUrlaub,
          props: {
            name: "von",
            label: "Datum von:",
            startMode: "day",
            popupPosition: "bottom center",
            dateFormat: "DD.MM.YYYY",            
            closable: true,
            autoComplete: "off",
            hideMobileKeyboard: true,
            minDate: minDatum,
            bisDate: bisDate,
            newBis: setBisDate,
            newVon: setVonDate,
          },
          colSize: {
            lg: 6,
            md: 6,
            sm: 6,
            xs: 6
          }
        },
        {
          validation: Yup.date().required("Bis-Datum ist erforderlich."),
          component: SimpleFormDatePickerUrlaub,
          props: {
            error: wrongDate,
            name: "bis",
            label: wrongDate ? <div> Datum bis: <Popup
            content={() => { return (<div>Bis-Datum liegt vor Von-Datum.</div>) }}
            trigger={<Icon name="warning" />} /></div> : "Datum bis:",
            startMode: "day",
            popupPosition: "bottom center",
            dateFormat: "DD.MM.YYYY",
            closable: true,
            autoComplete: "off",
            hideMobileKeyboard: true,
            minDate: vonDate,
            className: wrongDate ? "errorBorder" : "",
            newBis: setBisDate,
          },
          colSize: {
            lg: 6,
            md: 6,
            sm: 6,
            xs: 6
          }
        }, 
      ]
    }
  ];
  return (
    <div className={
      commonVersions.data?.hostEnvironment === "Test" ? "brand" : ""
    }>
      <InfoBox
        loading={abwesenheitsData.loading}
        anfrageInfo={abwesenheitsData.result.info}
        benutzername={""}
      />
      {abwesenheitsData.result.info ? <div>
        <Segment>
          <div className={"ui form"} >
            <br />
          <div className="m-bottom-15">
          <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-7 col-xs-7">
            <div className= "field">
            <label>Abwesenheitstyp</label>
                    <Dropdown
                      style={{ border: 'lightgrey 1px solid' }}
              name={`option`}
                      options={abwesenheitsArten}
                      value={option}
              selection
                      onChange={(event, { value }) => {
                        let stringVal = value as string;
                        let newVal = stringVal && stringVal.startsWith("Flag") ? 'Tagesstempel' : value ? value : 'Urlaub';
                setTagesStempel(ELookup.None);
                setOption(newVal as string);
                if (newVal === EAZTyp.Sonderurlaub && notiz.length <= 1) {
                  setError(true);
                }
                else if (newVal === EAZTyp.Tagesstempel && value !== undefined) {
                  setError(false);
                  setTagesStempel(value as ELookup  );
                  setOption(stringVal);
                }
                else if (error) {
                  setError(false);
                }
              }}
              />
                    <p>&nbsp; </p>
            </div>
                </div>
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <div className="field">
                    <label>Anmerkung</label>
                    <Input
                      style={error ? { border: 'red 1px solid' } : { border: 'lightgrey 1px solid' }}
                      className={error ? 'errorboarder' : '' }
                      name={`notizen`}
                      type={'text'}
                      onChange={(e, data) => {
                        note = data.value;
                        if ((data.value.length > 1) && error && (option === EAZTyp.Sonderurlaub)) {
                          setError(false);
                        }
                        if ((data.value.length <= 1) && !error && (option === EAZTyp.Sonderurlaub)) {
                          setError(true);
                        }
                      }}
                      onBlur={(data: any) => {
                        setNotiz(note);
                        if ((note.length > 1) && error && (option === EAZTyp.Sonderurlaub)) {
                          setError(false);
                        }
                        if ((note.length <= 1) && !error && (option === EAZTyp.Sonderurlaub)) {
                          setError(true);
                        }
                      }}
                    />
                    <p>{error && 'Begründung für Sonderurlaub fehlt.'}</p>
                  </div>
                </div>
              </div>
            </div>
            <SimpleForm
              loading={abwesenheitsData.loading}              
              intialValues={initModel}
            resetOnSubmit={true}
            formSchema={fields}
              onSubmit={(values) => {
                let enumVal: EAZTyp = option.startsWith("Flag") ? EAZTyp.Tagesstempel  : `${option}` as EAZTyp;
                if (values.von > values.bis) {
                  setInitModel(values);
                  setWrongDate(true);
                } 
                else {
                  setWrongDate(false);
                  values.typ = enumVal;
                  values.notizen = notiz;
                  abwesenheitsData.save({ del: false, urlaubsanfrageModel: values })
                }
              }}
              renderSubmitButtons={({ submitForm, dirty, isSubmitting, values }) => (
                <div style={{ paddingTop: '10px', paddingBottom: '20px' } }>
              <Button
                primary
                type="submit"
                content={'Beantragen'}
                className={'icon-button'}
                data-tooltip={"Beantragen"}
                floated='right'
                icon="save"
                size="small"
                onClick={submitForm}
                disabled={(isSubmitting || values.bis === undefined || values.von === undefined || error)}
                  />
                </div>
            )}
            />
          </div>
        </Segment>
        <br />
        {abwesenheitsData.result.urlaube && abwesenheitsData.result.urlaube.length !== 0 &&
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>Typ</Table.HeaderCell>
                <Table.HeaderCell collapsing>Von</Table.HeaderCell>
                <Table.HeaderCell collapsing>Bis</Table.HeaderCell>
                <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                <Table.HeaderCell collapsing>Anmerkung</Table.HeaderCell>
              <Table.HeaderCell collapsing width='1'></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {abwesenheitsData.result.urlaube && abwesenheitsData.result.urlaube.map((m, index) => {
                return (
                  <Table.Row key={`abwesenheit${index}` }>
                    <Table.Cell>{m.tagesstempelName ? m.tagesstempelName : m.typ ? m.typ : 'Urlaub'}</Table.Cell>
                    <Table.Cell>{m.von ? dateToShortDateString(m.von, false) : ''}</Table.Cell>
                    <Table.Cell>{m.bis ? dateToShortDateString(m.bis, false) : ''}</Table.Cell>
                    <Table.Cell>{m.status ? m.status : ''}</Table.Cell>
                    <Table.Cell>{m.notizen ? m.notizen : ''}</Table.Cell>
                    <Table.Cell>{m.status === EUrlaubsantragStatus.Offen ? <>
                      <Button
                        secondary
                        content={'Entfernen'}
                        data-tooltip={"Entfernen"}
                        size="small"
                        icon="trash"
                        type="button"
                        onClick={() => { setWarningOpen(true); setInitModel(m); }} /></> : ''}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        }
      </div> : <> </>
      }
      <div>
        { warningOpen &&
          <Modal style={{ position: "initial", height: "auto" }} size="small" open={warningOpen}>
            <Modal.Header>Antrag löschen?</Modal.Header>
            <Modal.Content>
              <p>Wollen Sie folgenden Antrag löschen?</p>
              <p><b>Antrag:</b> {initModel.tagesstempelName ? initModel.tagesstempelName : initModel.typ} von {dateToShortDateString(initModel.von!, false)} bis {dateToShortDateString(initModel.bis!, false)}</p>
              {initModel.notizen ? <p>{initModel.notizen} </p> : ''}
            </Modal.Content>
            <Modal.Actions>
              <Button content='Abbrechen' onClick={() => setWarningOpen(false)} />
              <Button secondary icon='check' content='Entfernen' positive onClick={() => { abwesenheitsData.save({ del: true, urlaubsanfrageModel: initModel }); setWarningOpen(false); }} />
            </Modal.Actions>
          </Modal>
        }
      </div>
    </div>

  );
};
