import * as React from "react";
import MitarbeiterApi from "../../api/MitarbeiterApi";
import CommonApi from "../../api/CommonApi";
import ZeitstempelApi from "../../api/ZeitstempelApi";
import { InfoBox } from "../InfoBoxen/InfoBox";
import { TagStempel, StempelModel, EAZTyp, StempelErfassenV2POSTRequest, EUIMode, EOptions } from "../../generated";
import { ZeitstempelRow } from "../../generated/models/ZeitstempelRow";
import { useQuery } from "react-query";
import useAsyncEdit from "../../hooks/useAsyncEdit";
import { dateToShortDateString } from "../Helper";
import { Button, Form, Grid, HeaderSubheader, Segment, Icon, Popup } from "semantic-ui-react";
import { Formik } from "formik";
import { Prompt, useHistory } from "react-router-dom";
import useMessages from "../../hooks/useMessages";
import { clearPositionWatch, watchPosition } from "../Zeiterfassung/Geolocation";
import useDocumentTitle, { DOCUMENT_TITLES } from "../../hooks/useDocumentTitle";

interface IZeiterfassungSProps { }

export const StempelAlsZeit = (datumVon: Date | undefined | null, datumBis: Date | undefined | null, index: number,
  isSonstigK: boolean, isSonstigG: boolean) => {
  let vonString = '';
  let bisString = '';
  let time = `${index + 1}. `;
  if (datumVon) {
    const vonHours = datumVon.getHours();
    const vonMinutes = datumVon.getMinutes();
    vonString = `${vonHours < 10 ? `0${vonHours}` : vonHours}:${vonMinutes < 10 ? `0${vonMinutes}` : vonMinutes}`;
  }
  if (datumBis) {
    const bisHours = datumBis.getHours();
    const bisMinutes = datumBis.getMinutes();
    bisString = `${bisHours < 10 ? `0${bisHours}` : bisHours}:${bisMinutes < 10 ? `0${bisMinutes}` : bisMinutes}`;
  }
  if (vonString) {
    time += `Von: ${vonString}${isSonstigK ? ' (sonstig) ' : ' '}`;
  }

  if (bisString) {
    time += `bis ${bisString}${isSonstigG ? ' (sonstig)' : ''}`;
  }
  return time;
};

const wochentag = new Array(7);
wochentag[0] = "Sonntag";
wochentag[1] = "Montag";
wochentag[2] = "Dienstag";
wochentag[3] = "Mittwoch";
wochentag[4] = "Donnerstag";
wochentag[5] = "Freitag";
wochentag[6] = "Samstag";

export const ZeiterfassungS: React.FC<IZeiterfassungSProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.zeiterfassung)
  let history = useHistory();
  const msg = useMessages();
  const [submit, SetSubmit] = React.useState<boolean>(false);
  /**AsyncEdit Hook für Zeitstempel-Api */
  const [datum, setDatum] = React.useState<Date>(new Date(new Date().toISOString().slice(undefined, new Date().toISOString().indexOf('T'))));
    

  /** Fetch von Daten die Gebraucht werden */
  const commonVersions = useQuery(["getVersionGET"], () =>
    CommonApi.getVersionGET({ clearCache: false })
  );

  /**
   * If GPS is enabled - tracks user location on page load and saves any changes to state 
   * when user clicks save, the current position is saved if the accuraccy is high enough
   * location-tracking is stopped when the components unmounts 
   */
  React.useEffect(() => {
    if (commonVersions.data?.options?.includes(EOptions.GPSDaten)) watchPosition(updatePosition)
  }, [commonVersions.data?.options])

  const [currentCoords, setCurrentCoords] = React.useState<GeolocationPosition | null>(null)
  const [locationWatchID, setLocationWatchID] = React.useState<number | null>(null)
  const updatePosition = (position: GeolocationPosition, watchID: number) => {
    setCurrentCoords(position)
    setLocationWatchID(watchID)
  }
  React.useEffect(() => {
    return () => {
      clearPositionWatch(locationWatchID)
    }
  }, [])

  const benutzername = useQuery(["getBenutzerVollnameGET"], () =>
    MitarbeiterApi.getBenutzerVollnameGET()
  );
  const zeitstempelRowData = useAsyncEdit<
    TagStempel,
    StempelErfassenV2POSTRequest
  >({
    queryKey: `getZeitstempelTagGET/${datum}`,
    default: {},
    load: (id) => ZeitstempelApi.getZeitstempelTagGET({ date: datum }),
    save: async (values, prev) => {
      try {
        if (commonVersions.data?.options?.includes(EOptions.GPSDaten)) {
          if (currentCoords?.coords?.accuracy && currentCoords?.coords?.accuracy <= 65 && values.tagStempel.zeitstempel) {
            values.tagStempel.zeitstempel.latitude = currentCoords.coords.latitude;
            values.tagStempel.zeitstempel.longitude = currentCoords.coords.longitude;
          } else {
          }
        }
        const result = await ZeitstempelApi.stempelErfassenV2POST(values);
        msg.addMessage({ header: "Stempel erfolgreich gespeichert!" }, "success", true, true);
        SetSubmit(false);
        setDatum(new Date(new Date().toISOString().slice(undefined, new Date().toISOString().indexOf('T'))));
        return result;
      } catch (e) {
        SetSubmit(false);
        return prev;
      }
    },
  });
  const zeitstempel = React.useMemo(() => zeitstempelRowData.result && (zeitstempelRowData.result || {}), [zeitstempelRowData.result]);
  const saveZeitstempel = React.useCallback(async (v) => {
    zeitstempelRowData.save({ tagStempel: v, date: datum });
  }, [zeitstempelRowData, datum]);

  /** Handler/Converter */
  const tagSaldoGearbeitet = (values: ZeitstempelRow[]) => {
    let zwischensumme: number = 0;
    for (let x = 0; x + 1 <= values.length; x += 1) {
      let dt1 = values && values[x] && values[x].von && values[x].von!.stempel ? values[x].von!.stempel! : undefined;
      let dt2 = values && values[x] && values[x].bis && values[x].bis!.stempel ? values[x].bis!.stempel! : undefined;
      if (dt1 && typeof dt1.getTime === 'function' && dt2 && typeof dt2.getTime === 'function') {
        let diff = (dt2?.getTime() - dt1?.getTime()) / 1000;
        zwischensumme += Math.abs(Math.round((diff /= 60)));
      }
    }
    return timeConvertToHoursAndMinutes(zwischensumme);
  };
  const timeConvertToHoursAndMinutes = (num: number) => {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    const time = new Date(datum);
    time.setHours(hours);
    time.setMinutes(minutes);
    return time;
  };

  let readonly = !zeitstempelRowData.result.isEditable;

  /*Funktionen*/
  function saveStemp(stempel: StempelModel, gehen: boolean, kommen: boolean, typ: EAZTyp) {
    let newZeitstempel: ZeitstempelRow = {};
    let dateTime = new Date();
    let info: string | undefined = '';
    if (gehen) {
      info = (typ === EAZTyp.Sonstig) ? 'Mitarbeiter früher gegangen' : undefined;
      newZeitstempel = {
        bis: { stempel: dateTime, info: info, typ: typ },
        von: { stempel: undefined, info: undefined, typ: EAZTyp.Kommen }
      };
    }
    if (kommen) {
      info = (typ === EAZTyp.Sonstig) ? 'Mitarbeiter später gekommen' : undefined;
      newZeitstempel = {
        bis: { stempel: undefined, info: undefined, typ: EAZTyp.Gehen },
        von: { stempel: dateTime, info: info, typ: typ }
      };
    }
    if (((zeitstempel && zeitstempel.zeitstempel && zeitstempel.zeitstempel.stempelRow)
      || (zeitstempel && zeitstempel.zeitstempel && zeitstempel.zeitstempel.montageRow))) {
      if ((typ !== 'Montage') && zeitstempel && zeitstempel.zeitstempel && zeitstempel.zeitstempel.stempelRow) {
        zeitstempel.zeitstempel.stempelRow.push(newZeitstempel);
      }
      else if (zeitstempel && zeitstempel.montage && (typ === 'Montage') && zeitstempel.zeitstempel && zeitstempel.zeitstempel.montageRow) {
        zeitstempel.zeitstempel.montageRow.push(newZeitstempel);
      }   
      saveZeitstempel(zeitstempel);
    }
  }


  //redirect wenn simplemode aktiviert und zb.: normalmode als favorit gespeichert
  if (zeitstempelRowData && zeitstempelRowData.result && (zeitstempelRowData.result.uiMode !== undefined)
    && (zeitstempelRowData.result.uiMode === EUIMode.Expert)) {
    history.push({
      pathname: `/zeiterfassung`
    });
  }

  return (
    <div
      className={
        commonVersions.data?.hostEnvironment === "Test" ? "brand" : ""
      }
    >
      <InfoBox
        benutzername={benutzername.data ?? ""}
        tagSaldo={tagSaldoGearbeitet(zeitstempelRowData.result && zeitstempelRowData.result.zeitstempel && zeitstempelRowData.result.zeitstempel.stempelRow
          && zeitstempel.zeitstempel && zeitstempel.zeitstempel.stempelRow ? zeitstempel.zeitstempel.stempelRow : [])}
        info={zeitstempelRowData.result && zeitstempelRowData.result.info}
        loading={benutzername.isLoading || zeitstempelRowData.loading}
        isSimpleMode={true}
      />
      {!zeitstempelRowData.loading && !benutzername.isLoading && zeitstempelRowData.result.formID &&
        <Formik key={zeitstempelRowData.result.formID!}
          initialValues={zeitstempelRowData.result}
          enableReinitialize          
          onSubmit={(values, action) => {
        }}
      >
        {({
          dirty,
          values,
          handleSubmit,
        }) => (
          <div>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <br />
              <Segment>
                <h3 style={{ textAlign: "center" }}>Zeiterfassung {dateToShortDateString(datum, false)} 
                    <HeaderSubheader content={<div>{wochentag[datum.getDay()]}</div>}/>
                  </h3>{benutzername.isLoading || zeitstempelRowData.loading ?
                  <Segment loading={true}> </Segment>
                  :
                  !benutzername.isLoading && benutzername.data && benutzername.data.toString() && !zeitstempelRowData.loading
                    && (zeitstempelRowData.result.showStempel === EAZTyp.Null) &&
                      zeitstempelRowData.result && zeitstempelRowData.result.zeitstempel && zeitstempelRowData.result.zeitstempel.stempelRow
                      && zeitstempelRowData.result.zeitstempel.stempelRow !== undefined && zeitstempelRowData.result.zeitstempel.stempelRow !== null
                      ? <div>
                        {zeitstempelRowData.result.isEditable && <>
                          <Grid rows="2">
                            <Grid.Column className={'simpleUI-grid'} width='8'>
                              <Button content={'Kommen'} className={'simpleUI-button-kommen'} floated="left" type="button"
                              fluid disabled={submit || readonly} onClick={() => saveStemp(values && values.zeitstempel!, false, true, EAZTyp.Kommen)}/>
                          </Grid.Column>
                            <Grid.Column className={'simpleUI-grid'}  width='8'>
                              <Button content={'Gehen'} className={'simpleUI-button-gehen'} floated="right" type="button"
                              fluid disabled={submit || readonly} onClick={() => saveStemp(values && values.zeitstempel!, true, false, EAZTyp.Gehen)}/>
                          </Grid.Column>
                        </Grid>
                          <br />
                          <Grid rows="2">
                            <Grid.Column className={'simpleUI-grid'} width='8'>
                              <Button content={'Urlaub'} className={'simpleUI-button-link'} floated="left" type="button"
                                fluid disabled={submit || readonly} onClick={() => history.push({
                                  pathname: `/abwesenheit`
                                })} />
                            </Grid.Column>
                            <Grid.Column className={'simpleUI-grid'} width='8'>
                              <Button content={'Zeitsaldo'} className={'simpleUI-button-link'} floated="right" type="button"
                                fluid disabled={submit || readonly} onClick={() => history.push({
                                  pathname: `/monatsuebersicht`
                                })} />
                            </Grid.Column>
                          </Grid>
                          <br />
                          {zeitstempelRowData.result.montage ? <div>
                            <Grid rows="2" >
                              <Grid.Column className={'simpleUI-grid'} width='8'>
                                <Button content={'Montage Anfang'} className={'simpleUI-button-montageA'} type="button" fluid floated="left"
                                   disabled={submit || readonly} onClick={() => saveStemp(values && values.zeitstempel!, false, true, EAZTyp.Montage)} />
                                <Button content={'Montage Ende'} className={'simpleUI-button-montageE'} type="button" fluid floated="right"
                                  disabled={submit || readonly} onClick={() => saveStemp(values && values.zeitstempel!, true, false, EAZTyp.Montage)} />
                                </Grid.Column>
                              <Grid.Column className={'simpleUI-grid'} width='8'>
                                <Button content={'Kommen Sonstig'} className={'simpleUI-button-kommenS'} type="button" fluid floated="left"                            
                                  disabled={(submit || readonly || ((values.zeitstempel!.stempelRow &&
                                    (values.zeitstempel!.stempelRow.find(f => (f.von && ((f.von.stempel !== undefined) && (f.von.stempel !== null)))
                                      || (f.bis && ((f.bis.stempel !== undefined) && (f.bis.stempel !== null))))))) ? true : false)}
                                  onClick={() => saveStemp(values && values.zeitstempel!, false, true, EAZTyp.Sonstig)} />
                                <Button content={'Gehen Sonstig'} className={'simpleUI-button-gehenS'} type="button" fluid floated="right"
                                  
                                  disabled={(submit || readonly)}
                                  onClick={() => saveStemp(values && values.zeitstempel!, true, false, EAZTyp.Sonstig)} />
                              </Grid.Column>
                            </Grid>
                          </div>
                            :
                            <div>
                              <Grid rows="2">
                                <Grid.Column className={'simpleUI-grid'} width='8'>
                                  <Button content={'Kommen Sonstig'} className={'simpleUI-button-kommen'} floated="left" type="button"
                                    fluid
                                    disabled={(submit || readonly || ((values.zeitstempel!.stempelRow &&
                                      (values.zeitstempel!.stempelRow.find(f => (f.von && ((f.von.stempel !== undefined) && (f.von.stempel !== null)))
                                        || (f.bis && ((f.bis.stempel !== undefined) && (f.bis.stempel !== null))))))) ? true : false)}
                                    onClick={() => saveStemp(values && values.zeitstempel!, false, true, EAZTyp.Sonstig)} />
                                </Grid.Column>
                                <Grid.Column className={'simpleUI-grid'} width='8'>
                                  <Button content={'Gehen Sonstig'} className={'simpleUI-button-gehen'} floated="right" type="button"
                                    fluid
                                    disabled={(submit || readonly)}
                                    onClick={() => saveStemp(values && values.zeitstempel!, true, false, EAZTyp.Sonstig)} />
                                </Grid.Column>
                                </Grid>
                            </div>}                        
                        </>}
                        {((zeitstempelRowData.result.zeitstempel!.stempelRow && zeitstempelRowData.result.zeitstempel!.stempelRow[0] &&
                          ((zeitstempelRowData.result.zeitstempel!.stempelRow[0] && zeitstempelRowData.result.zeitstempel!.stempelRow[0].von
                         && zeitstempelRowData.result.zeitstempel!.stempelRow[0].von.stempel) || (zeitstempelRowData.result.zeitstempel!.stempelRow[0]
                            && zeitstempelRowData.result.zeitstempel!.stempelRow[0].bis && zeitstempelRowData.result.zeitstempel!.stempelRow[0].bis.stempel)))
                          ||
                          (zeitstempelRowData.result.zeitstempel!.montageRow && zeitstempelRowData.result.zeitstempel!.montageRow[0] &&
                        ((zeitstempelRowData.result.zeitstempel!.montageRow[0] && zeitstempelRowData.result.zeitstempel!.montageRow[0].von
                          && zeitstempelRowData.result.zeitstempel!.montageRow[0].von.stempel) || (zeitstempelRowData.result.zeitstempel!.montageRow[0]
                        && zeitstempelRowData.result.zeitstempel!.montageRow[0].bis && zeitstempelRowData.result.zeitstempel!.montageRow[0].bis.stempel)))
                          ) &&
                          <>
                          <Grid key={`Grid-`}>
                            {(zeitstempelRowData.result.zeitstempel!.stempelRow && zeitstempelRowData.result.zeitstempel!.stempelRow[0] &&
                              ((zeitstempelRowData.result.zeitstempel!.stempelRow[0] && zeitstempelRowData.result.zeitstempel!.stempelRow[0].von
                                && zeitstempelRowData.result.zeitstempel!.stempelRow[0].von.stempel) || (zeitstempelRowData.result.zeitstempel!.stempelRow[0]
                                && zeitstempelRowData.result.zeitstempel!.stempelRow[0].bis && zeitstempelRowData.result.zeitstempel!.stempelRow[0].bis.stempel))) && <>
                                <Grid.Column width='8'>
                                  <Grid.Row><h4>Arbeitszeit: </h4></Grid.Row>
                                  {zeitstempelRowData.result.zeitstempel!.stempelRow.map((f, index: number) => {
                                    return (
                                      <Grid.Row>
                                        <p>{StempelAlsZeit(f.von && f.von.stempel, f.bis && f.bis.stempel, index,
                                          (f.von && (f.von.typ && f.von.typ.includes('Sonstig'))) ? true : false,
                                          (f.bis && (f.bis.typ && f.bis.typ.includes('Sonstig'))) ? true : false) 
                                        } {(((f.von && f.von.fehler) || (f.bis && f.bis.fehler))) ?
                                            <Popup
                                              content={<>{f.von && f.von.fehler} {f.bis && f.bis.fehler}</>}
                                              trigger={
                                                <Icon
                                                  style={{ marginLeft: '0.2em' }}
                                                  name={'info circle'}
                                                />}
                                            /> : <></>} </p>
                                      </Grid.Row>

                                    )
                                  })}
                                </Grid.Column>
                              </>}
                            {(zeitstempelRowData.result.montage && zeitstempelRowData.result.zeitstempel!.montageRow && zeitstempelRowData.result.zeitstempel!.montageRow[0] &&
                              ((zeitstempelRowData.result.zeitstempel!.montageRow[0] && zeitstempelRowData.result.zeitstempel!.montageRow[0].von
                                && zeitstempelRowData.result.zeitstempel!.montageRow[0].von.stempel) || (zeitstempelRowData.result.zeitstempel!.montageRow[0]
                                && zeitstempelRowData.result.zeitstempel!.montageRow[0].bis && zeitstempelRowData.result.zeitstempel!.montageRow[0].bis.stempel))) &&
                            <>
                              <Grid.Column width='8'>
                              <Grid.Row><h4>Montage: </h4></Grid.Row>
                                {zeitstempelRowData.result.zeitstempel!.montageRow.map((f, index: number) => {
                                return (
                                  <Grid.Row>
                                    <p>{StempelAlsZeit(f.von && f.von.stempel, f.bis && f.bis.stempel, index, false, false)}
                                      {(((f.von && f.von.fehler) || (f.bis && f.bis.fehler))) ?
                                        <Popup
                                          content={<>{f.von && f.von.fehler} {f.bis && f.bis.fehler}</>}
                                          trigger={
                                            <Icon
                                              style={{ marginLeft: '0.2em' }}
                                              name={'info circle'}
                                            />}
                                        /> : <></>}
                                    </p>
                                  </Grid.Row>

                                )
                              })}
                            </Grid.Column>
                          </>}
                          </Grid>
                          </>
                        }
                    </div>
                    : zeitstempelRowData.result.showStempel && zeitstempelRowData.result.showStempel !== EAZTyp.Null && <div style={{ textAlign: 'center' }}>Ganztägige Abwesenheit: {zeitstempelRowData.result.showStempel}
                      <HeaderSubheader content={<h4>Für Buchungen wenden Sie sich bitte an die Administration</h4>} />
                      </div>}
                  <Prompt
                    when={!zeitstempelRowData.loading && dirty && !submit}
                    message="Daten verwerfen ohne zu speichern?"
                  />
              </Segment>
            </Form>
          </div>)}
        </Formik>
      }
    </div>
  );
};
