import * as React from 'react';
import { useQuery } from 'react-query';
import MitarbeiterApi from '../api/MitarbeiterApi';
import { Grid, Segment, Table, Icon, Popup, Button } from 'semantic-ui-react';
import { numberToStringTime } from "./Helper";
import { useHistory } from 'react-router-dom';
import { MonthPicker } from './MonthPicker';
import useDocumentTitle, { DOCUMENT_TITLES } from '../hooks/useDocumentTitle';
import { EOptions } from '../generated';
import { Legende } from './Legende';

interface MonatsuebersichtProps {
}

export const Monatsuebersicht: React.FC<MonatsuebersichtProps> = (props) => {
  useDocumentTitle(DOCUMENT_TITLES.monatsansicht)
  const startFilter = new Date();
  let history = useHistory();
  function redirect(datum?: Date | null) {
    if (datum) {
      let dateString = datum.toISOString().slice(undefined, datum.toISOString().indexOf('T'))
      history.push({
        pathname: `zeiterfassung/${dateString}`,
        state: { date: datum },
      });
    }
  }

  const [monatsFilter, setMonatsFilter] = React.useState((startFilter.getMonth() + 1)); // Jänner Monat wäre 0 etc
  const [jahresFilter, setJahresFilter] = React.useState(startFilter.getFullYear());
  const monatsUebersicht = useQuery(["getMonatsuebersichtGET", jahresFilter, monatsFilter], () =>
    MitarbeiterApi.getMonatsuebersichtGET({ jahr: jahresFilter, monat: monatsFilter })
  );

  return (
    <div>
      {monatsUebersicht.data &&
        <Segment>
          <h3 style={{ textAlign: "center" }}>Saldoübersicht</h3>
        <Grid>
          <Grid.Column mobile={'6'} tablet={'4'} computer={'2'}>
              <Grid.Row>
                {`Anwesenheitstage: `}
              </Grid.Row>
              <Grid.Row>
                {`Urlaubstage: `}
              </Grid.Row>
              <Grid.Row>
                {`Krankheitstage: `}
              </Grid.Row>
            </Grid.Column>
          <Grid.Column mobile={'1'} tablet={'2'} computer={'2'}>
              <Grid.Row>
                {`${monatsUebersicht.data.anwesenheitsTage}`}
              </Grid.Row>
              <Grid.Row>
                {`${monatsUebersicht.data.urlaubstage}`}
              </Grid.Row>
              <Grid.Row>
                {`${monatsUebersicht.data.krankheitsTage}`}
              </Grid.Row>
            </Grid.Column>
          {/*<Grid.Column mobile={'6'} tablet={'3'} computer={'2'}>*/}
          {/*    <Grid.Row>*/}
          {/*      {`Fehlende Tage: `}*/}
          {/*    </Grid.Row>*/}
          {/*    <Grid.Row>*/}
          {/*      {`Freie Tage: `}*/}
          {/*    </Grid.Row>*/}
          {/*    <Grid.Row>*/}
          {/*      {`ZA Tage: `}*/}
          {/*    </Grid.Row>*/}
          {/*  </Grid.Column>*/}
          {/*<Grid.Column mobile={'1'} tablet={'2'} computer={'1'}>*/}
          {/*    <Grid.Row>*/}
          {/*      {`${monatsUebersicht.data.fehlendeTage}`}*/}
          {/*    </Grid.Row>*/}
          {/*    <Grid.Row>*/}
          {/*      {`${monatsUebersicht.data.freieTage}`}*/}
          {/*    </Grid.Row>*/}
          {/*    <Grid.Row>*/}
          {/*      {`${monatsUebersicht.data.zeitausgleichsTage}`}*/}
          {/*    </Grid.Row>*/}
          {/*  </Grid.Column>*/}
          <Grid.Column mobile={'6'} tablet={'3'} computer={'2'}>
              <Grid.Row>
                <b>{`Resturlaub: `}</b>
              </Grid.Row>
              <Grid.Row>
                {`Vormonat: `}
              </Grid.Row>
              <Grid.Row>
                {`Monatsende: `}
              </Grid.Row>
            </Grid.Column>
          <Grid.Column mobile={'2'} tablet={'2'} computer={'2'}>
              <Grid.Row>
                Tage
            </Grid.Row>
              <Grid.Row>
                {`${monatsUebersicht.data.restUrlaubVorMonat}`}
              </Grid.Row>
              <Grid.Row>
                {`${monatsUebersicht.data.restUrlaub}`}
              </Grid.Row>
          </Grid.Column>
          <Grid.Column mobile={'5'} tablet={'4'} computer={'1'}>
              <Grid.Row>
                <b>{`Überstunden: `}</b>
              </Grid.Row>
              <Grid.Row>
                {`geleistet: `}
              </Grid.Row>
              <Grid.Row>
                {`abzurechnen: `}
              </Grid.Row>
            </Grid.Column>
          <Grid.Column mobile={'3'} tablet={'2'} computer={'1'}>
              <Grid.Row className="align-row-right">
                {`50%`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.geleisteteUeberstunden50}`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.ausbezahlteUeberstunden50}`}
              </Grid.Row>
            </Grid.Column>
          <Grid.Column mobile={'3'} tablet={'2'} computer={'1'}>
              <Grid.Row className="align-row-right">
                {`100%`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.geleisteteUeberstunden100}`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.ausbezahlteUeberstunden100}`}
              </Grid.Row>
            </Grid.Column>
          <Grid.Column mobile={'3'} tablet={'2'} computer={'1'}>
              <Grid.Row className="align-row-right">
                {`MA/ZS`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.plusMinusUebertrag}`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.ausbezahlteUeberstundenGesamt}`}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column mobile={'5'} tablet={'2'} computer={'1'}></Grid.Column>
          <Grid.Column mobile={'5'} tablet={'2'} computer={'1'}>
              <Grid.Row>
                {`Vormonat: `}
              </Grid.Row>
              <Grid.Row>
                {`Monat: `}
              </Grid.Row>
              <Grid.Row>
              <b>{`Zeitsaldo: `}</b>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column className={'mobile-padding-column'} mobile={'2'} tablet={'2'} computer={'1'}>
            <Grid.Row className="align-row-right">
              {`${monatsUebersicht.data.plusMinusVorMonat}`}
            </Grid.Row>
            <Grid.Row className="align-row-right">
              {`${monatsUebersicht.data.plusMinusAktuell}`}
              </Grid.Row>
              <Grid.Row className="align-row-right">
                {`${monatsUebersicht.data.plusMinusGesamt}`}
              </Grid.Row>
          </Grid.Column>
          </Grid>
        </Segment>
      }
      <MonthPicker 
        isLoading={monatsUebersicht.isLoading}
        monatsFilter={monatsFilter}
        setMonatsFilter={setMonatsFilter}
        jahresFilter={jahresFilter}
        setJahresFilter={setJahresFilter}
        showAll={undefined}
        setShowAll={() => { } }
        title="Monatsübersicht">
      </MonthPicker>
      {monatsUebersicht.data && monatsUebersicht.data.monatsAnsicht ?
        <>
          <div className="container_table">
            <Table className="monatsTabelle" unstackable key={`ansicht-${startFilter}`}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Wochentag</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>Tag</Table.HeaderCell>
                  <Table.HeaderCell>Kommen</Table.HeaderCell>
                  <Table.HeaderCell className="table-header-extend">Gehen &nbsp;</Table.HeaderCell>
                  <Table.HeaderCell>Kommen</Table.HeaderCell>
                  <Table.HeaderCell className="table-header-extend">Gehen &nbsp;</Table.HeaderCell>
                  <Table.HeaderCell>Kommen</Table.HeaderCell>
                  <Table.HeaderCell className="table-header-extend">Gehen &nbsp;</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>Pause</Table.HeaderCell>
                  {monatsUebersicht.data.monatsAnsicht.find(f => f.umziehzeit && f.umziehzeit.length > 1)
                    && <Table.HeaderCell textAlign='right'>Umziehen</Table.HeaderCell>}
                  {monatsUebersicht.data.monatsAnsicht.find(f => f.montage && f.montage.length > 1)
                    && <Table.HeaderCell textAlign='right'>Montage</Table.HeaderCell>}
                  <Table.HeaderCell textAlign='center'>IST</Table.HeaderCell>
                  <Table.HeaderCell textAlign='right'>SOLL</Table.HeaderCell>
                  <Table.HeaderCell textAlign='center'>+/-</Table.HeaderCell>
                  <Table.HeaderCell>50%</Table.HeaderCell>
                  <Table.HeaderCell>100%</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Info</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {monatsUebersicht.data.monatsAnsicht.map(ArbeitsTag => {
                  let className = `${ArbeitsTag.style ? ArbeitsTag.style : "arbeitsTag"} align-tablerow-right`;
                  let classNameCenter = `${ArbeitsTag.style ? ArbeitsTag.style : "arbeitsTag"}`;
                  return (
                    <Table.Row className={classNameCenter}>
                      <Table.Cell className={classNameCenter ? classNameCenter + '-first' : ''}>{ArbeitsTag.wochentag}</Table.Cell>
                      <Table.Cell textAlign='center' className={classNameCenter}>{ArbeitsTag.tag}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.kommen1}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.gehen1}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.kommen2}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.gehen2}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.kommen3}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{ArbeitsTag.gehen3}</Table.Cell>
                      <Table.Cell textAlign='center' className={classNameCenter}>{ArbeitsTag.kommenGehen && ArbeitsTag.kommenGehen.length > 0 ?
                        <div>
                          <Popup
                            content={() => {
                              return (
                                <>
                                  <h4>Zeiterfassungen: </h4>
                                  {ArbeitsTag!.kommenGehen!.map(m => { return (<p>{m}</p>) })}
                                </>
                              )
                            }}
                            key={ArbeitsTag.tag}
                            trigger={<Icon
                              name={'info circle'}> </Icon>}
                          />
                        </div>
                        : <div></div>
                      }
                      </Table.Cell>
                      <Table.Cell className={className}>{ArbeitsTag.pause}</Table.Cell>
                      {monatsUebersicht.data && monatsUebersicht.data.monatsAnsicht && monatsUebersicht.data.monatsAnsicht.find(f => f.umziehzeit && f.umziehzeit.length > 1)
                        && <Table.Cell className={className}>{ArbeitsTag.umziehzeit}</Table.Cell>}
                      {monatsUebersicht.data && monatsUebersicht.data.monatsAnsicht && monatsUebersicht.data.monatsAnsicht.find(f => f.montage && f.montage.length > 1)
                        && <Table.Cell textAlign='center' className={classNameCenter}>{ArbeitsTag.montage}</Table.Cell>}
                      <Table.Cell data-tooltip={ArbeitsTag.istSonstig ? `Sonstige Arbeitszeit: ${ArbeitsTag.istSonstig}` : undefined} className={className}>{ArbeitsTag.ist}</Table.Cell>
                      <Table.Cell className={className}>{ArbeitsTag.soll}</Table.Cell>
                      <Table.Cell className={className}>{ArbeitsTag.plusMinus}</Table.Cell>
                      <Table.Cell className={className}>{ArbeitsTag.saldo50 ? `${numberToStringTime(ArbeitsTag.saldo50)}` : ''}</Table.Cell>
                      <Table.Cell className={className}>{ArbeitsTag.saldo100 ? `${numberToStringTime(ArbeitsTag.saldo100)}` : ''}</Table.Cell>
                      <Table.Cell textAlign='center' className={classNameCenter}>{ArbeitsTag.code}</Table.Cell>
                      <Table.Cell className={classNameCenter}>{classNameCenter === 'fehler' ?
                        <div style={{ textAlign: 'right' }}>
                          <Popup
                            content={() => { return (ArbeitsTag.info) }}
                            key={ArbeitsTag.tag}
                            trigger={<Icon name={'warning circle'}> </Icon>}
                          />
                        </div>
                        : ArbeitsTag.info
                      }
                      </Table.Cell>
                      <Table.Cell className={classNameCenter ? classNameCenter + "-last" : ''}>
                        {ArbeitsTag.symbol && <Button
                          className="table-button"
                          size="mini"
                          icon={`${ArbeitsTag.symbol}`}
                          primary
                          onClick={() => redirect(new Date(`${jahresFilter}-${monatsFilter < 10 ? `0${monatsFilter}` : monatsFilter}-${ArbeitsTag.tag && ArbeitsTag.tag < 10 ? `0${ArbeitsTag.tag}` : ArbeitsTag.tag}`))}
                        />
                        }
                      </Table.Cell>
                    </Table.Row>
                  )
                }
                )}
              </Table.Body>
            </Table>
          </div>
          <Segment>
            <Legende 
              codes={["GF - Gesetzlicher Feiertag", "HF - halber Feiertag", "BF - Betriebsfrei", "Z - Zeitausgleich", "S - Sonderurlaub", "U - Urlaub", "K - Krank", "A - Anwesend"]}
              codesMitPaket={[{zusatzpaket:EOptions.Montage, codes:["M - Montage", "Am - Montagefehler"]}]}
              colors={[{ css: "samstag", content:"Samstag" }, {css: "sonntag", content:"Sonntag"}, {css:"feiertag", content:"Feiertag"}, {css:"fehler-box", content:"Fehler"}]}
            />
          </Segment>
        </>
        : monatsUebersicht.data && monatsUebersicht.data.monatsAnsicht?.length === 0 && <Segment > <h3>Keine Arbeitsaufzeichnungen für diesen Monat vorhanden.</h3> </Segment>
      }
    </div>
  )
}